.music-cover__circle {
  width: 50px;
  height: 50px;
  left: 50%;
  top: 50%;
  background-color: black;
  box-shadow: inset 0 0 2px green;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
.music-cover__circle2 {
  width: 60px;
  height: 60px;
  left: 50%;
  top: 50%;
  background-color: transparent;
  border: 4px solid black;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
.music-cover__image {
  background-size: cover;
  box-shadow: 0 0 40px 3px rgb(128, 0, 122);
  background-position: center;
  animation: rotate-image 10s linear infinite;
}

@keyframes rotate-image {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
// Chuc nang chinh
.music-player-function {
  width: 600px;
  max-height: 680px;
}
.list-songs-popup {
  cursor: pointer;
  border-bottom: 0.1px solid rgba($color: #eeeeee, $alpha: 0.1);
}
.song-popup-singer {
  font-style: italic;
}
.list-panel-songs {
  top: -60px;
  box-shadow: 0 0 31px 10px rgb(128, 0, 122);
}
.more-option {
  top: -135px;
  right: 10px;
}
.background {
  top: -500px;
  bottom: -70px;
  z-index: 2;
  background-color: rgba($color: #000000, $alpha: 0.8);
}
#timeLine {
  $color: rgb(236, 236, 236);
  background: gray;
  -webkit-transition: 0.1s;
  transition: opacity 0.1s;
  -webkit-appearance: none;
  opacity: 0.7;
  outline: none;
  &:hover {
    opacity: 1;
  }
  &::-webkit-slider-thumb {
    width: 14px;
    height: 6px;
    background: $color;
    -webkit-appearance: none;
    appearance: none;
    opacity: 1;
    border-radius: 10px;
  }
  &::-moz-range-thumb {
    width: 14px;
    height: 6px;
    background: $color;
    opacity: 1;
    border-radius: 10px;
  }
}
.optinal-controller {
  button {
    padding: 0.25rem 0.75rem;
  }
}
button {
  transition: opacity 0.1s;
  opacity: 0.9;
  &:focus {
    outline: none;
  }
  &:hover {
    opacity: 1;
  }
}
.form-timeout {
  z-index: 2;
}
.volume-bar {
  width: 220px;
  height: 44px;
  left: -90px;
  bottom: 126px;
  z-index: 3;
  background-color: black;
  box-shadow: 0 0 10px 6px rgb(128, 0, 122);
  transform: rotate(-90deg);
}
#volume {
  $color: rgb(236, 236, 236);
  width: 200px;
  height: 8px;
  background: gray;
  -webkit-transition: 0.1s;
  transition: opacity 0.1s;
  -webkit-appearance: none;
  opacity: 0.65;
  border-radius: 2px;
  outline: none;
  &:hover {
    opacity: 1;
  }
  &::-webkit-slider-thumb {
    width: 14px;
    height: 12px;
    background: $color;
    -webkit-appearance: none;
    appearance: none;
    opacity: 1;
    border-radius: 4px;
  }
  &::-moz-range-thumb {
    width: 14px;
    height: 12px;
    background: $color;
    opacity: 1;
    border-radius: 4px;
  }
}
.tooltip {
  left: 30px;
  bottom: -168px;
  box-shadow: 0 0 16px 8px rgb(128, 0, 122);
}
