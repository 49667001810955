@tailwind base;
@tailwind components;
@tailwind utilities;

.btn-calculator {
  @apply text-white hover:text-yellow-600 focus:outline-none transform hover:scale-110;
}
.btn-operator {
  @apply text-yellow-600 hover:text-white focus:outline-none transform hover:scale-110;
}
.link-dashboard {
  @apply border bg-gradient-to-r hover:shadow-md cursor-pointer rounded-md text-center align-middle flex;
}
.song-popup-title {
  @apply text-sm font-bold overflow-hidden whitespace-nowrap overflow-ellipsis;
}
