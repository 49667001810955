svg path {
  color: white;
}

.pane {
  display: flex;
  flex-direction: column;
  width: 310px;
  justify-content: center;
  padding: 10px;
  background: #f8f9fb;
  border: 1px #ccc solid;
  border-radius: 7px;
  > h5 {
    text-align: center;
    margin: 6px 0 14px 0;
    text-transform: capitalize;
    font-weight: 600;
  }
  > button {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 10px auto 16px;
    font-size: 16px;
  }
}
.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  label {
    margin: 0 0 4px;
    display: block;
    font-size: medium;
    font-weight: 500;
  }
  p {
    font-size: 12px;
    color: red;
    margin: 4px 0 0 0;
    padding: 0 0 0 2px;
  }
  .invalid {
    border: 1px solid red;
  }
  input {
    box-sizing: border-box;
    height: 32px;
    margin: 4px 0;
    padding-left: 12px;
    padding-right: 12px;
    font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 12px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 16px;
    color: rgb(71, 77, 102);
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(216, 218, 229);
    transition: box-shadow 80ms ease-in-out;
    outline: 0px rgb(71, 77, 102);
    border-radius: 4px;
    &:focus {
      box-shadow: #5fa9ff 0px 0px 3px;
    }
  }
  [class^="error-message-"] {
    color: red;
    font-size: 12px;
    padding: 0 0 0 4px;
    min-height: 16px;
  }
}
.fullname {
  color: blue;
}
.policy {
  font-size: 12px;
  text-align: center;
}
