.navbar {
  background: rgb(251, 251, 251);
  height: 54px;
  position: fixed;
  width: 100%;
  box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  z-index: 999;
}
