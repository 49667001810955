* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.dice-body {
  width: 100%;
  height: 100vh;
  margin-top: -60px;
  position: relative;
  font-weight: 300;
  color: #555;
  background-color: white;
}

.container {
  width: 1200px;
  height: 700px;
  position: absolute;
  left: 50%;
  top: 50%;
  box-shadow: 1px 10px 50px rgba(0, 0, 0, 0.3);
  transform: translate(-50%, -50%);
}

/**********************************************
*** PLAYERS
**********************************************/
.player-panel {
  float: left;
  width: 50%;
  height: 100%;
  padding: 100px;
  background-color: #fff;
  transition: all 0.3s ease;
}
.player-name {
  margin-bottom: 10px;
  margin-top: 20px;
  position: relative;
  font-size: 40px;
  font-weight: 100;
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
}

.player-score {
  margin-bottom: 130px;
  font-size: 80px;
  font-weight: 100;
  text-align: center;
  color: #42b983;
}

.active {
  background-color: rgba(161, 161, 161, 0.198);
}
.active .player-name {
  font-weight: 300;
  text-decoration: underline;
}

.active .player-name::after {
  content: "...đang chơi";
  position: absolute;
  top: -17px;
  right: 25px;
  font-size: 17px;
  color: #42b983;
}

.player-current-box {
  width: 40%;
  margin: 0 auto;
  padding: 12px;
  text-align: center;
  color: #fff;
  background-color: #42b983;
}

.player-current-label {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  color: white;
}

.player-current-score {
  font-size: 30px;
}

.winner.player-name {
  font-weight: 300;
  color: #42b983;
  text-decoration: underline;
}

/**********************************************
*** Control
**********************************************/
.control {
  width: 200px;
  position: absolute;
  left: 50%;
  font-family: Lato;
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
  color: #555;
  border: 1px solid black;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  transform: translateX(-50%);
  border-radius: 1px;
}
.control.disable {
  pointer-events: none;
}

.control:hover {
  font-weight: 600;
}
.control:hover i {
  margin-right: 20px;
}

.control:focus {
  outline: none;
}

.control i {
  display: inline-block;
  margin-right: 15px;
  margin-top: -4px;
  font-size: 32px;
  line-height: 1;
  vertical-align: text-top;
  color: #42b983;
  transition: margin 0.3s;
}

.btn-new {
  top: 45px;
}
.btn-roll {
  top: 440px;
}
.btn-hold {
  top: 512px;
}

#dice-1 {
  top: 120px;
}
#dice-2 {
  top: 250px;
}

.dice {
  width: 120px;
  height: 120px;
  position: absolute;
  left: 50%;
  box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
  transform: translateX(-50%);
}
.spinner div {
  width: 120px;
  height: 120px;
  position: absolute;
  border: 1px solid #ccc;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  opacity: 1;
}

.spinner .face1 {
  background-image: url("../img/DiceGame/dice-1.png");
  background-size: cover;
  -webkit-transform: translateZ(60px);
  -ms-transform: translateZ(60px);
  transform: translateZ(60px);
  background-position: center;
}
.spinner .face2 {
  background-image: url("../img/DiceGame/dice-2.png");
  background-size: cover;
  -webkit-transform: rotateY(90deg) translateZ(60px);
  -ms-transform: rotateY(90deg) translateZ(60px);
  transform: rotateY(90deg) translateZ(60px);
  background-position: center;
}
.spinner .face3 {
  background-image: url("../img/DiceGame/dice-3.png");
  background-size: cover;
  -webkit-transform: rotateY(90deg) rotateX(90deg) translateZ(60px);
  -ms-transform: rotateY(90deg) rotateX(90deg) translateZ(60px);
  transform: rotateY(90deg) rotateX(90deg) translateZ(60px);
  background-position: center;
}
.spinner .face4 {
  background-image: url("../img/DiceGame/dice-4.png");
  background-size: cover;
  -webkit-transform: rotateY(180deg) rotateZ(90deg) translateZ(60px);
  -ms-transform: rotateY(180deg) rotateZ(90deg) translateZ(60px);
  transform: rotateY(180deg) rotateZ(90deg) translateZ(60px);
  background-position: center;
}
.spinner .face5 {
  background-image: url("../img/DiceGame/dice-5.png");
  background-size: cover;
  -webkit-transform: rotateY(-90deg) rotateZ(90deg) translateZ(60px);
  -ms-transform: rotateY(-90deg) rotateZ(90deg) translateZ(60px);
  transform: rotateY(-90deg) rotateZ(90deg) translateZ(60px);
  background-position: center;
}
.spinner .face6 {
  background-image: url("../img/DiceGame/dice-6.png");
  background-size: cover;
  -webkit-transform: rotateX(-90deg) translateZ(60px);
  -ms-transform: rotateX(-90deg) translateZ(60px);
  transform: rotateX(-90deg) translateZ(60px);
  background-position: center;
}

.spinner {
  -webkit-transition: all 0.9s ease;
  -o-transition: all 0.9s ease;
  transition: all 0.9s ease;
  -webkit-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  -ms-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  -o-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  -webkit-transform-origin: 60px 60px 0;
  -ms-transform-origin: 60px 60px 0;
  transform-origin: 60px 60px 0;
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.spinner.dice-1 {
  -webkit-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  -ms-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  -o-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
}
.spinner.dice-2 {
  -webkit-transform: rotateX(0deg) rotateY(-90deg) rotateZ(0deg);
  -ms-transform: rotateX(0deg) rotateY(-90deg) rotateZ(0deg);
  -o-transform: rotateX(0deg) rotateY(-90deg) rotateZ(0deg);
  transform: rotateX(0deg) rotateY(-90deg) rotateZ(0deg);
}
.spinner.dice-3 {
  -webkit-transform: rotateX(0deg) rotateY(-90deg) rotateZ(90deg);
  -ms-transform: rotateX(0deg) rotateY(-90deg) rotateZ(90deg);
  -o-transform: rotateX(0deg) rotateY(-90deg) rotateZ(90deg);
  transform: rotateX(0deg) rotateY(-90deg) rotateZ(90deg);
}
.spinner.dice-4 {
  -webkit-transform: rotateX(0deg) rotateY(180deg) rotateZ(90deg);
  -ms-transform: rotateX(0deg) rotateY(180deg) rotateZ(90deg);
  -o-transform: rotateX(0deg) rotateY(180deg) rotateZ(90deg);
  transform: rotateX(0deg) rotateY(180deg) rotateZ(90deg);
}
.spinner.dice-5 {
  -webkit-transform: rotateX(0deg) rotateY(90deg) rotateZ(0deg);
  -ms-transform: rotateX(0deg) rotateY(90deg) rotateZ(0deg);
  -o-transform: rotateX(0deg) rotateY(90deg) rotateZ(0deg);
  transform: rotateX(0deg) rotateY(90deg) rotateZ(0deg);
}
.spinner.dice-6 {
  -webkit-transform: rotateX(90deg) rotateY(90deg) rotateZ(0deg);
  -ms-transform: rotateX(90deg) rotateY(90deg) rotateZ(0deg);
  -o-transform: rotateX(90deg) rotateY(90deg) rotateZ(0deg);
  transform: rotateX(90deg) rotateY(90deg) rotateZ(0deg);
}
.spinner.dice-7 {
  -webkit-transform: rotateX(90deg) rotateY(90deg) rotateZ(0deg);
  -ms-transform: rotateX(90deg) rotateY(90deg) rotateZ(0deg);
  -o-transform: rotateX(90deg) rotateY(90deg) rotateZ(0deg);
  transform: rotateX(180deg) rotateY(180deg) rotateZ(0deg);
}
